<template>
  <div>
    <HeaderBar/>
    <Header steps="true" stepNumb="4"/>
    <Steps step_a="4" completed="[true, true, true]"/>
    <div class="background-grey background-form"> 
      <div class="container">
        <div class="bloque-informacion-basica form-paso4" id="paso4">
          <div class="row align-items-start">
            <div class="informacion-basica-titulo col">
              <h2 class="informacion-basica-texto">{{$t('RecomendationsTeamworks.title')}}</h2>	  
              <div class="divider-wrap ">
                <div class="divider-middle mx-auto"></div>
              </div>
            </div>
          </div>
          <form class="paso4" @submit.prevent="saveAndContinue();scroll2error();" novalidate> 

<!--            <div class="bloque-info-lines row"> 
              <h2 class="bloque-info col-12 text-center">{{$t('RecomendationsTeamworks.sectionOne.title')}}</h2>
              <p class="bloque-info col-12 text-center">{{$t('RecomendationsTeamworks.sectionOne.subtitle')}}</p>
            </div>-->



<!--              <div class="col-12 col-md-4 offset-xl-1 col-xl-4">
                <div class="form-group">
                  <label for="recomend-nombre" class="form-required">{{$t('RecomendationsTeamworks.sectionOne.name')}}</label>
                  <input v-model="recommendation.name" class="form-control" :class="{'input-blue':recommendation.name!==null}" id="recomend-nombre" rows="1">
                  <div class="tooltip-error" v-if="formchecked && v$.recommendation.name.required.$invalid">
                    <div class="arrow"></div>
                    <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                  </div>
                  <div class="tooltip-error" v-if=" formchecked && v$.recommendation.name.maxLength.$invalid">
                    <div class="arrow"></div>
                    <span class="tooltip-text">{{$t('errors.fieldMaxlength')}} 100</span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4 col-xl-3">
                <div class="form-group">
                  <label for="recomend-1apellido" class="form-required">{{$t('RecomendationsTeamworks.sectionOne.surname')}}</label>
                  <input v-model="recommendation.firstname" class="form-control" :class="{'input-blue':recommendation.firstname!==null}" id="recomend-1apellido" rows="1" >
                  <div class="tooltip-error" v-if="formchecked && v$.recommendation.firstname.required.$invalid">
                    <div class="arrow"></div>
                    <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                  </div>
                  <div class="tooltip-error" v-if="formchecked && v$.recommendation.firstname.maxLength.$invalid">
                    <div class="arrow"></div>
                    <span class="tooltip-text">{{$t('errors.fieldMaxlength')}} 100</span>
                  </div>

                </div>
              </div>
              <div class="col-12 col-md-4 col-xl-3">
                <div class="form-group">
                  <label for="recomend-2apellido" class="">{{$t('RecomendationsTeamworks.sectionOne.lastname')}}</label>
                  <input v-model="recommendation.lastname" class="form-control" :class="{'input-blue':recommendation.lastname!==null}" id="recomend-2apellido" rows="1">
                  <div class="tooltip-error" v-if="formchecked && v$.recommendation.lastname.maxLength.$invalid">
                    <div class="arrow"></div>
                    <span class="tooltip-text">{{$t('errors.fieldMaxlength')}} 100</span>
                  </div>
                
                </div>
              </div>
              <div class="col-12 col-md-12 offset-xl-1 col-xl-10">
                <div class="form-group">
                  <label for="extracurriculares-textarea" class="form-required">{{$t('RecomendationsTeamworks.sectionOne.relationship')}}</label>
                  <input v-model="recommendation.relationship_student" class="form-control" :class="{'input-blue':recommendation.relationship_student!==null}" id="recomendacion-textarea">

                  <div class="tooltip-error" v-if="formchecked && v$.recommendation.relationship_student.required.$invalid">
                    <div class="arrow"></div>
                    <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                  </div>
                  <div class="tooltip-error" v-if="formchecked && v$.recommendation.relationship_student.maxLength.$invalid">
                    <div class="arrow"></div>
                    <span class="tooltip-text">{{$t('errors.fieldMaxlength')}} 100</span>
                  </div>

                </div>
              </div>-->

            <div class="row align-items-start">


                    <div class="bloque-info-lines">
                        <h2 class="bloque-info col-12 text-center  col-xl-12"><b>{{$t('RecomendationsTeamworks.sectionOne.title')}}</b></h2>
                        <p class="bloque-info col-12 text-center  col-xl-11">{{$t('RecomendationsTeamworks.sectionOne.subtitle')}}</p>
                    </div>


                    <div class="col-12 offset-xl-1 col-xl-10">
                        <div class="form-group">
                            <label for="academic-goals-textarea" class="form-required">{{$t('RecomendationsTeamworks.sectionOne.academic_goals')}}</label>
                            <textarea v-model.trim="question_one" class="form-control textarea2" :class="{'input-blue':question_one!=null&&question_one!=''}" id="academic-goals-textarea" rows="5"></textarea>
                            <span class="maxLengthField">{{$t('videoPresentation.sectionThree.shortDescriptionMax3')}}</span>
                            <div class="tooltip-error" v-if="formchecked && (question_one=='' || question_one==null)">
                                <div class="arrow"></div>
                                <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                            </div>
                            <div class="tooltip-error" v-if="(question_one!=null) && v$.question_one.words_validation.$invalid">
                                <div class="arrow"></div>
                                <span class="tooltip-text">{{$t('errors.fieldMaxWords')}} 150</span>
                            </div>
                        </div>
                    </div>



                    <div class="col-12 offset-xl-1 col-xl-10">
                        <div class="form-group">
                            <label for="inspirations-aspirations-textarea" class="form-required">{{$t('RecomendationsTeamworks.sectionOne.inspirations_aspirations')}}</label>
                            <textarea v-model.trim="question_two" class="form-control textarea2" :class="{'input-blue':question_two!=null&&question_two!=''}" id="inspirations-aspirations-textarea" rows="5"></textarea>
                            <span class="maxLengthField">{{$t('videoPresentation.sectionThree.shortDescriptionMax3')}}</span>
                            <div class="tooltip-error" v-if="formchecked && (question_two=='' || question_two==null)">
                                <div class="arrow"></div>
                                <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                            </div>
                            <div class="tooltip-error" v-if="(question_two!=null) && v$.question_two.words_validation.$invalid">
                                <div class="arrow"></div>
                                <span class="tooltip-text">{{$t('errors.fieldMaxWords')}} 150</span>
                            </div>
                        </div>

            </div>







                        <div class="bloque-info-lines">
                            <h2 class="bloque-info col-12 text-center  col-xl-11"><b>{{$t('videoPresentation.sectionOne.title')}}</b></h2>
                            <p class="bloque-info col-12 text-center  col-xl-11">{{$t('videoPresentation.sectionOne.subtitle')}}</p>
                        </div>
                        <div class="row align-items-start">
                            <div class="col-12 offset-xl-1 col-xl-11">
                                <p for="" class="form-required label-text">{{$t('videoPresentation.sectionOne.attachVideo')}}</p>
                                <div class="form-group text-center">
                                    <input v-model="presentation" type="text" id="adjuntar-video" name="calificaciones" class="form-control btn-margin-extra col-md-12 col-xl-11" :class="{'input-blue':presentation!==null}" @blur="checkURL">
                                    <div class="tooltip-error" v-if="formchecked && v$.presentation.required.$invalid">
                                        <div class="arrow"></div>
                                        <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                    </div>
                                    <div class="tooltip-error" v-if="formchecked && v$.presentation.maxLength.$invalid">
                                        <div class="arrow"></div>
                                        <span class="tooltip-text">{{$t('errors.fieldMaxlength')}} 2000</span>
                                    </div>
                                    <div class="tooltip-error" v-if="formchecked && v$.presentation.url.$invalid">
                                        <div class="arrow"></div>
                                        <span class="tooltip-text">{{$t('errors.fieldURL')}}</span>
                                    </div>

                                </div>
                            </div>
                            <p class="bloque-info col-12 text-center" style="margin-top:0" v-html="$t('videoPresentation.sectionOne.posttitle')"></p>
                        </div>




              <div class="col-12 offset-md-1 col-md-11 offset-md-1 offset-xl-2 col-xl-9"> 
                <button @click="backStep()" type="button" class="rounded-pill btn-lg btn-primary mb-2 form-btn-back col-md-5">{{$t('RecomendationsTeamworks.buttons.back')}}</button>
                <button type="submit" class="rounded-pill btn-lg btn-primary mb-2 form-btn-submit offset-md-1 offset-xl-1 col-md-5">{{$t('RecomendationsTeamworks.buttons.save')}}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <ModalExpireToken v-if="expireToken"/>
    <ModalServiceError v-if="serviceError" :service-error="serviceError" @close-modal-error="modalErrorClose" :literal="serviceErrorMsg" />
    <ModalWorking v-if="showModalWorking" :show-modal-working="showModalWorking"/>

    <Footer/>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import ModalExpireToken from '@/components/ModalExpireToken.vue'
import ModalServiceError from '@/components/ModalServiceError.vue'
import ModalWorking from '@/components/ModalWorking.vue'
import HeaderBar from '@/components/HeaderBar.vue'
import Header from '@/components/Header.vue'
import Steps from '@/components/Steps.vue'
import Footer from '@/components/Footer.vue'
import useVuelidate from '@vuelidate/core'
import { required, maxLength, url } from "@vuelidate/validators"


    const words_validation = (value) =>  {
        if (value==null) {
            return true;
        }
        let split_arr = value.split(' ');
        return (split_arr.length < 201);
    };


export default {
  name: 'RecommendationsTeamworks',
  components: {
    HeaderBar,
    Header,
    Steps,
    Footer,
    ModalExpireToken,
    ModalServiceError,
    ModalWorking
  },
  setup () {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    return {
      question_one: null,
      question_two: null,
      presentation: null,
      expireToken: false,
      serviceError: false,
      serviceErrorMsg: null,
      showModalWorking: true,
      formchecked: false
    }
  },
  validations: {
      question_one: { required, words_validation },
      question_two: { required, words_validation },
      presentation: { required, maxLength: maxLength(2000), url }
  },
  computed: {
    ...mapState([
      'step',
      'msalConfig',
      'enviroments',
      'RecommendationsTeamworks',
      'idAdmission'
    ]),
    account() {
      return Object.keys(this.msalConfig.account).length !== 0 ? this.msalConfig.account : undefined;
    },
    accessToken() {
      return Object.keys(this.msalConfig.accessToken).length !== 0 ? this.msalConfig.accessToken : undefined;
    }
  },
  mounted() {

    window.scrollTo(0,0); 

    this.getRecommendationsTeamworks();

    this.$store.watch(() => {
        return this.$store.state.RecommendationsTeamworks.presentation
    }, (res) => {
        if ((res === undefined) || (res === null)) return;
        console.log(res);
        this.presentation = res;
    })


  },
  methods: {
    getRecommendationsTeamworks() {
      this.axios.get(`${this.enviroments.urlDev}/Recommendation?token=${this.accessToken}&id_admision=${this.idAdmission}`).then(response => {

        this.showModalWorking = false;
        document.body.style.overflowY = "auto";


        if (response.data.status === 1) {
          this.serviceError = true;
          this.serviceErrorMsg =  response.data.message;
        } else if(response.data.status === 2) {
          this.expireToken = true;
        }
        else if(response.data.status === 0){

          this.$store.commit('RecommendationsTeamworks/setPresentation', response.data.presentation)
          this.presentation = response.data.presentation;
          this.question_one = response.data.question_one;
          this.question_two = response.data.question_two;

        }

      })
      .catch( error => {
        console.log(error);
        this.serviceError = true;
        this.serviceErrorMsg =  `ERROR:: REFERENCE LETTER get Reference Letter: ${error}`;
        this.showModalWorking = false;
        document.body.style.overflowY = "auto";
      });
    },
    setRecommendationsTeamworks() {



/*          if (!this.step.step1PhotoCompleted)
          {
            this.serviceError = true;
            this.serviceErrorMsg =  this.$t('errors.step1PhotoCompleted');          
          }
          else
          {*/

            if ((this.step.step1Completed && this.step.step2Completed && this.step.step3Completed) || (this.step.step_admission > 3))
            {

              this.showModalWorking = true;
              document.body.style.overflowY = "hidden";

              this.axios.post(`${this.enviroments.urlDev}/Recommendation?token=${this.accessToken}&id_admision=${this.idAdmission}`, {
                presentation: this.presentation,
                question_one: this.question_one,
                question_two: this.question_two
              }).then(response => {
                
                this.showModalWorking = false;

                if (response.data.status === 1) {
                  this.serviceError = true;
                  this.serviceErrorMsg =  response.data.message;
                } else if(response.data.status === 2) {
                  this.expireToken = true;
                } else if(response.data.status === 0) {
                  this.$router.push('payment-fee');
                  this.step.firstStep = false;
                  this.step.step4Completed = true;
                }
              })
              .catch( error => {
                console.log(error);
                this.serviceError = true;
                this.serviceErrorMsg =  `ERROR:: REFERENCE LETTER set Reference Letter: ${error}`;
                this.showModalWorking = false;
              });

            }
            else{
              this.serviceError = true;
              this.serviceErrorMsg =  this.$t('errors.stepsCompleted');          
            }

          //}



    },
    loadFile(e, param) {

      let file = e.target.files[0]
      console.log(file)
      let reader = new FileReader()

      reader.onload = (e) => {
        if(param === 'document') {
          this.document = {
            name: file.name,
            delete: false,
            file: e.target.result.replace("data:image/jpeg;base64,","").replace("data:application/pdf;base64,","")
          }
        }
      }

      reader.readAsDataURL(file);
    },
    scroll2error()
    {
        setTimeout(function () { if (document.getElementsByClassName('tooltip-error').length != 0) {document.getElementsByClassName('tooltip-error')[0].parentElement.scrollIntoView();}}.bind(this), 100)
    },
    saveAndContinue() {
      this.v$.$touch()
      this.formchecked = true;

      if(this.v$.presentation.$invalid || this.v$.question_two.$invalid || this.v$.question_one.$invalid) return
      this.setRecommendationsTeamworks();
    },
    modalErrorClose()
    {this.serviceError=false},
    backStep() {
      this.$router.push('video-presentation');
      this.step.firstStep = false;
    },
    checkURL() {
      if (this.presentation.search('http://')==-1 && this.presentation.search('https://')==-1)
      {this.presentation = 'https://'+this.presentation}
    }

  }
}
</script>
